import axios from "axios";

function createAxios() {
  axios.defaults.baseURL = "https://www.interqr.com/api";

  return axios.create({
    baseURL: "https://www.interqr.com/api",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "Referrer-Policy": "no-referrer",
    },
  });
}

export const $axios = createAxios();
