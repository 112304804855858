<template>
  <div class="search-empty">
    <div class="search-empty-image">
      <img src="@/assets/img/search-empty.svg" alt="Not found" />
    </div>

    <h2>Sorry</h2>
    <p>Apartments not found</p>
  </div>
</template>

<script>
export default {
  name: "SearchEmpty",
};
</script>

<style lang="scss">
.search-empty {
  * {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  h2 {
    font-size: 35px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  p {
    font-size: 28px;
  }
}
</style>
