<template>
  <div class="svg-icon" :style="{ width: size + 'px', height: size + 'px' }">
    <svg
      :width="size"
      :height="size"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="name === 'process'"
      :style="{ color: color || 'inherit' }"
    >
      <path
        d="M8.96875 1.5625C11.2819 1.81938 13.1094 3.64438 13.3688 5.9575"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.96875 3.77734C10.0756 3.99234 10.9406 4.85797 11.1563 5.96484"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.8947 7.79524C9.38782 10.2877 9.9534 7.4042 11.5408 8.99051C13.0711 10.5205 13.9514 10.827 12.0118 12.7655C11.7689 12.9606 10.2258 15.3089 4.80279 9.88729C-0.620879 4.465 1.72598 2.92028 1.92121 2.67747C3.86485 0.733654 4.16676 1.61836 5.69712 3.14833C7.28383 4.73531 4.40158 5.30276 6.8947 7.79524Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      :width="size"
      :height="size"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="name === 'call'"
      :style="{ color: color || 'inherit' }"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.16701 7.83526C9.99262 10.6601 10.6336 7.39208 12.4327 9.18989C14.1671 10.9239 15.164 11.2713 12.9665 13.4682C12.6912 13.6894 10.9423 16.3508 4.79613 10.2062C-1.35085 4.06098 1.30899 2.3103 1.53026 2.03512C3.7331 -0.167875 4.07452 0.834797 5.80896 2.56876C7.60804 4.36734 4.34141 5.01044 7.16701 7.83526Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      :width="size"
      :height="size"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="name === 'ended'"
      :style="{ color: color || 'inherit' }"
    >
      <path
        d="M14.2146 1.85547L10.1094 5.96073"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.1094 1.85547L14.2146 5.96073"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.17946 7.82321C9.90877 10.5518 10.5279 7.39512 12.2657 9.13171C13.941 10.8066 14.9047 11.1422 12.7813 13.2643C12.5154 13.478 10.8261 16.0487 4.88937 10.1135C-1.04812 4.17747 1.52107 2.48641 1.7348 2.2206C3.86258 0.0926313 4.19309 1.06116 5.86843 2.73607C7.60546 4.47339 4.45016 5.0946 7.17946 7.82321Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      :width="size"
      :height="size"
      viewBox="0 0 502 247"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="name === 'light-active'"
      :style="{ color: color || 'inherit' }"
    >
      <path
        d="M394.667 1.13309H352C351.787 1.13309 351.573 1.34609 351.36 1.13309C350.613 0.706095 349.653 0.813102 349.013 1.4531C348.586 1.8801 348.053 1.8801 347.626 2.0931C347.413 2.2001 347.306 2.2001 347.199 2.2001L221.439 65.1331H10.667C4.8 65.1331 0 69.9331 0 75.7991V182.466C0 188.333 4.8 193.133 10.667 193.133H221.014L346.454 245.4H347.947C347.947 245.4 350.614 246.467 352.107 246.467H394.774C400.641 246.467 405.441 235.8 405.441 235.8V11.8001C405.333 5.9331 400.533 1.13309 394.667 1.13309ZM213.333 171.799H21.333V86.4661H213.333V171.799ZM341.333 219.799L234.666 175.319V82.4121L341.333 29.0791V219.799ZM384 225.133H362.667V22.4661H384V225.133Z"
        fill="currentColor"
      />
      <path
        d="M437.332 129.133H490.345C495.678 129.133 500.478 125.293 501.225 120.066C502.185 113.453 497.065 107.799 490.665 107.799H437.652C432.319 107.799 427.519 111.639 426.772 116.866C425.812 123.479 430.932 129.133 437.332 129.133Z"
        fill="currentColor"
      />
      <path
        d="M437.331 65.1335C439.251 65.1335 441.171 64.6005 442.771 63.6405L496.104 31.6405C501.117 28.6535 502.824 22.0405 499.731 17.0275C496.638 12.0145 490.131 10.3075 485.118 13.4005L431.785 45.4005C426.772 48.3875 425.065 55.0005 428.158 60.0135C430.078 63.2135 433.598 65.1335 437.331 65.1335Z"
        fill="currentColor"
      />
      <path
        d="M496.108 205.293L442.775 173.293C437.762 170.306 431.148 171.906 428.162 176.92C425.176 181.934 426.775 188.547 431.789 191.533L485.122 223.533C490.135 226.52 496.749 224.92 499.735 219.906C502.721 214.892 501.228 208.386 496.108 205.293Z"
        fill="currentColor"
      />
    </svg>

    <svg
      :width="size"
      :height="size"
      viewBox="0 0 502 247"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="name === 'light'"
      :style="{ color: color || 'inherit' }"
    >
      <path
        d="M394.667 1.13309H352C351.787 1.13309 351.573 1.34609 351.36 1.13309C350.613 0.706095 349.653 0.813102 349.013 1.4531C348.586 1.8801 348.053 1.8801 347.626 2.0931C347.413 2.2001 347.306 2.2001 347.199 2.2001L221.439 65.1331H10.667C4.8 65.1331 0 69.9331 0 75.7991V182.466C0 188.333 4.8 193.133 10.667 193.133H221.014L346.454 245.4H347.947C347.947 245.4 350.614 246.467 352.107 246.467H394.774C400.641 246.467 405.441 235.8 405.441 235.8V11.8001C405.333 5.9331 400.533 1.13309 394.667 1.13309ZM213.333 171.799H21.333V86.4661H213.333V171.799ZM341.333 219.799L234.666 175.319V82.4121L341.333 29.0791V219.799ZM384 225.133H362.667V22.4661H384V225.133Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
const acceptedIconNames = [
  "call",
  "process",
  "pended",
  "light-active",
  "light",
];

export default {
  name: "SvgIcon",
  props: {
    size: { type: Number, default: 24 },
    name: {
      type: String,
      required: true,
      validator: (name) => acceptedIconNames.indexOf(name) !== -1,
    },
    color: { type: [String, null], default: null },
  },
};
</script>

<style>
.svg-icon {
  color: inherit;
}
</style>
