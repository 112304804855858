import { $axios } from "@/plugins/axios";
import store from "@/store";

class Api {
  constructor(http) {
    this.http = http;
  }

  async request({ url, method = "get", params = null, useLoading = true }) {
    if (useLoading) {
      store.commit("setLoading", true);
    }

    try {
      const { data } = await this.http({ url, method, data: params });

      if (data) {
        const { data: nestedData } = data;

        if (nestedData) {
          return nestedData;
        }

        return data;
      }

      return {};
    } catch (err) {
      console.log(err);
      return {};
    } finally {
      if (useLoading) {
        store.commit("setLoading", false);
      }
    }
  }

  async getResidents(qrUUID) {
    return await this.request({ url: `/call/apartments?qr_uuid=${qrUUID}` });
  }

  async startCall(resident_idx, select_token) {
    return await this.request({
      url: `/call/start-call`,
      method: "post",
      params: {
        resident_idx,
        select_token,
      },
      useLoading: false,
    });
  }
}

export const api = new Api($axios);
