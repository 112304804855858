<template>
  <div class="app-wrapper">
    <div class="app" :class="{ [name]: true }">
      <div class="app-nav">
        <div class="before-logo" v-if="useBack">
          <button class="button button-icon" type="button" @click="back">
            <img src="@/assets/img/icons/back.svg" alt="Back" />
          </button>
        </div>

        <img
          class="blue-angle angle-image"
          src="@/assets/img/blue-angle.svg"
          alt="#"
          v-if="useAngles"
        />

        <img
          class="purple-angle angle-image"
          src="@/assets/img/purple-angle.svg"
          alt="#"
          v-if="useAngles"
        />

        <div class="app-logo">
          <img src="@/assets/img/logo.svg" alt="Inter Qr" />
        </div>

        <h1 class="app-title">{{ title }}</h1>
      </div>
      <div class="app-body">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: [String, undefined],
      default: "",
    },

    name: {
      type: [String, undefined],
      default: "",
    },

    useAngles: { type: Boolean, default: false },
    useBack: { type: Boolean, default: false },
  },

  methods: {
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="scss">
.residents-modal {
  z-index: 99999999;
}

.app-wrapper {
  overflow: hidden !important;
  position: relative;
  max-width: 100%;
  width: 100%;
  @media (min-width: 600px) {
    width: 600px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.app {
  width: 100%;
  max-width: 100%;
  padding: 20px;

  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;

    border: 1px solid var(--border-color);
    border-radius: 14px;
    margin-top: 50px;
  }
}

.app-nav {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  position: relative;

  .button {
    margin-right: 20px;
  }

  .angle-image {
    position: absolute;
    top: 40px;
    width: 60px;
    height: auto;
    z-index: -1;
    transition: 0.35s;

    &.blue-angle {
      width: 130px;
      left: 0;
      transform: translateX(-50%);
    }

    &.purple-angle {
      right: -30px;
    }
  }
}

.app-logo {
  width: 85px;
  display: block;
  img {
    width: 100%;
    height: auto;
  }
}

.app-title {
  font-size: 20px;
  text-align: right;
  margin: 0;
  margin-left: auto;
}

.app.Residents {
  .angle-image {
    opacity: 0;
    transform: translateX(100%);

    &.blue-angle {
      opacity: 0;
      transform: translateX(-100%);
    }
  }
}

.app-body {
  display: flex;
  & > * {
    width: 100%;
  }
}
</style>
