<template>
  <ResidentsView
    :id="selectedApartmentId"
    :opened="!!selectedApartmentId"
    @hide="residentsViewHideHandler"
  />

  <CallModal
    family="Bares Family"
    apartment="Spartacus"
    :opened="callModalOpened"
    id="1"
    @close="closeCallModal"
  />

  <AppPreloader :opened="loading" />

  <AppLayout :title="pageTitle" :name="name" :use-angles="true">
    <router-view />
  </AppLayout>
</template>

<script>
import ResidentsView from "./components/ResidentsView.vue";
import AppLayout from "./components/AppLayout.vue";
import AppPreloader from "./components/AppPreloader.vue";
import CallModal from "./components/CallModal.vue";
import { navTitles } from "@/constants/nav-titles";
import { mapGetters } from "vuex";

export default {
  components: { CallModal, AppPreloader, AppLayout, ResidentsView },
  name: "App",

  computed: {
    ...mapGetters(["loading", "callModalOpened"]),
    selectedApartmentId() {
      return this.$store.getters.selectedApartmentId;
    },

    name() {
      return this.$route.name;
    },

    pageTitle() {
      const path = this.$route.fullPath;

      if (path === "/") {
        return navTitles[path];
      }

      return Object.entries(navTitles)
        .filter(([title]) => title !== "/")
        .find(([title]) => path.includes(title))?.[1];
    },
  },

  created() {
    document.title = "Inter QR";
  },

  methods: {
    closeCallModal() {
      this.$store.commit("hideCallModal");
    },

    back() {
      if (this.prevRoute) {
        this.$router.push(this.prevRoute);
      }
    },
    residentsViewHideHandler() {
      this.$store.commit("setSelectedApartmentId", null);
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
