import { api } from "@/api/Api";
import { createStore } from "vuex";
import { call } from "./modules/call";

export default createStore({
  state: {
    qrId: "",
    apartments: [],
    loading: false,
    select_token: null,
    selectedApartmentId: null,
    searchQuery: "",
  },

  getters: {
    searchQuery: ({ searchQuery }) => searchQuery,
    select_token: ({ select_token }) => select_token,
    selectedApartmentId: ({ selectedApartmentId }) => selectedApartmentId,
    qrId: ({ qrId }) => qrId,
    loading: ({ loading }) => loading,
    apartments: ({ apartments }) => apartments,
    apartmentsName({ apartments }) {
      return (id) => {
        if (!id) {
          return null;
        }
        return (
          apartments.find(({ id: apartId }) => id === apartId)?.name ?? null
        );
      };
    },

    residents({ apartments }) {
      return (id) => {
        if (!id) {
          return null;
        }

        return (
          apartments.find((apartment) => apartment.id?.toString() === id)
            ?.residents || []
        );
      };
    },
  },

  mutations: {
    setSearchQuery(state, searchQuery) {
      state.searchQuery = searchQuery;
    },
    cleanState(state) {
      state.select_token = null;
      state.selectedApartmentId = null;
      state.loading = false;
      state.apartments = [];
      state.searchQuery = "";
    },

    setSelectedApartmentId(state, id) {
      state.selectedApartmentId = id;
    },

    setQrId(state, qrId) {
      state.qrId = qrId;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setApartments(state, apartments) {
      state.apartments = apartments;
    },

    setSelectToken(state, token) {
      state.select_token = token;
    },
  },

  actions: {
    cleanState({ dispatch, commit }) {
      commit("cleanState");
      dispatch("fetchResidents");
    },

    async fetchResidents({ getters, commit }) {
      const { apartments: stateApartmants, qrId } = getters;
      if (stateApartmants.length) {
        return stateApartmants;
      }

      const { apartments, select_token } = await api.getResidents(qrId);

      if (select_token) {
        commit("setSelectToken", select_token);
      }

      if (apartments) {
        commit(
          "setApartments",
          apartments
            .map(
              ({
                apartment_name: name,
                apartment_floor: floor,
                apartment_number: apt,
                resident_idx,
                residents,
              }) => {
                let resultResidentsIdx = resident_idx;

                if (residents.length === 1) {
                  resultResidentsIdx = residents[0]?.resident_idx ?? -1;
                }

                return {
                  name,
                  floor,
                  apt,
                  id: apt || `apartments-${Math.random()}`,
                  resident_idx: resultResidentsIdx.toString(),
                  hasResidents: resultResidentsIdx === -1,
                  residents: residents.map(
                    ({ resident_idx: id, resident_name: name }) => ({
                      resident_idx: id.toString(),
                      id: id || `resident-${Math.random()}`,
                      name,
                    })
                  ),
                };
              }
            )
            .filter(({ resident_idx, residents }) => {
              return resident_idx !== -1 || residents.length;
            })
        );
      }
    },
  },
  modules: {
    call,
  },
});
