export function FloatingText({
  msg = "Dialing...",
  text_color = "#fff",
  background_color = "transparent",
  blinking = true,
  status = "process",
} = {}) {
  this.msg = msg;
  this.text_color = text_color;
  this.background_color = background_color;
  this.blinking = blinking;
  this.status = status;

  return this;
}
