<template>
  <div class="apartment-card--wrapper" @click="clickHandler">
    <div class="apartment-card" v-bind="$attrs">
      <div class="apartment-card--body">
        <h2 class="apartment-card--name">{{ title }}</h2>

        <div class="apartment-card--extra-info" v-if="floor || apt">
          <span class="small" v-if="floor"> Floor {{ floor }} </span>
          <span class="small" v-if="apt"> Apt {{ apt }} </span>
        </div>
      </div>

      <div class="apartment-card--icon">
        <img src="@/assets/img/icons/ring.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
const actions = ["ring", "go"];

export default {
  name: "ApartmentCard",
  inheritAttrs: false,
  emits: ["ring"],
  props: {
    id: { type: [String, Number], required: true },
    title: {
      type: String,
      required: true,
    },
    floor: { type: [String, null], default: null },
    apt: { type: [String, null], default: null },
    hasResidents: { type: Boolean, default: false },
    residentIdx: { type: [String, null], default: "-1" },
  },

  computed: {
    action() {
      if (this.hasResidents) {
        return actions[1];
      }

      return actions[0];
    },
  },

  methods: {
    ...mapMutations(["setLoading"]),
    clickHandler() {
      this[this.action]();
    },

    ring() {
      this.$store.dispatch("callPrepare", this.residentIdx);
      this.setLoading(true);

      window.call.startProcess = () => {
        this.setLoading(false);
        this.$emit("ring", this.id);
      };

      setTimeout(() => {
        window.call.startProcess();
      }, 5000);
    },

    go() {
      this.$store.commit("setSelectedApartmentId", this.id);
    },
  },
};
</script>

<style lang="scss">
:root {
  --apartment-card-height: 52px;
}

.apartment-card--wrapper {
  will-change: opacity, height;
  overflow: hidden;
  &:not(:first-child) {
    .apartment-card {
      margin-top: 12px;
    }
  }

  &:last-child {
    .apartment-card {
      border-bottom: none;
    }
  }
}

.apartment-card {
  --size: 18px;
  --color: inherit;

  color: var(--color);
  height: var(--apartment-card-height);
  display: flex;
  align-items: center;
  padding: 0 0 12px;
  cursor: pointer;

  border-bottom: 1px solid #e3eaea;

  @media (any-hover: hover) {
    transition: color 0.2s;
    &:hover {
      --color: var(--light-blue);
    }
  }

  // layout
  &--body {
    width: calc(100% - var(--size));
    padding-right: 20px;
  }

  &--icon {
    width: var(--size);
    img {
      width: 100%;
    }
  }

  // components
  &--name {
    font-size: var(--size);
  }

  &--extra-info {
    font-size: calc(var(--size) * 0.67);
    color: #989baa;
    padding-top: 0.4em;

    span {
      font-size: 1em;
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
}
</style>
