<template>
  <div class="app-modal" :class="{ opened }" @click="close">
    <div class="modal-window" @click.stop v-bind="$attrs"><slot /></div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "AppModal",
  props: { opened: { type: Boolean, default: false } },
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
$duration: 0.4s;
$delay: 0.1s;

.app-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999999;
  transition: $duration;
  background-color: rgba(#000, 0.5);

  &:not(.opened) {
    opacity: 0;
    pointer-events: none;
  }
}

.modal-window {
  background: #fff;
  position: absolute;
  width: 100%;
  height: 100%;

  @media (max-width: 599px) {
    min-width: 100%;
  }

  @media (min-width: 600px) {
    width: 600px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: $duration !important;
  }
}

.app-modal {
  &.opened {
    .modal-window {
      transition: transform $duration $delay, opacity $duration;
    }
  }
  &:not(.opened) {
    transition-delay: $duration / 3;

    .modal-window {
      transition: transform $duration, opacity $duration $delay;
      transform: translate(-50%, -40%) scale(1.2);

      @media (max-width: 600px) {
        transform: translateX(100%);
      }
    }
  }
}
</style>
