import { api } from "@/api/Api.js";
import { FloatingText } from "@/types/FloatingText";
import { callStatuses } from "@/types/callStatuses";

export const call = {
  state() {
    return {
      callModel: null,
      callModalOpened: false,
      light: false,
      callStatusConfig: new FloatingText(),
      callStatus: callStatuses[0],
      activeResidentIdx: null,
    };
  },

  getters: {
    activeResidentIdx: ({ activeResidentIdx }) => activeResidentIdx,
    callStatus: ({ callStatus }) => callStatus,
    callStatusConfig: ({ callStatusConfig }) => callStatusConfig,
    callModalOpened: ({ callModalOpened }) => callModalOpened,
    light: ({ light }) => light,
    callModel: ({ callModel }) => callModel,
  },

  mutations: {
    cleanCallState(state) {
      state.callModel = null;
      state.callStatus = callStatuses[0];
      state.activeResidentIdx = null;
      state.light = false;
      state.callModalOpened = false;
      state.callStatusConfig = new FloatingText();
    },
    setActiveResidentIdx(state, residentsIdx) {
      state.activeResidentIdx = residentsIdx;
    },

    setCallStatus(state, status) {
      state.callStatus = status;
    },

    setCallStatusConfig(state, callStatusConfig) {
      state.callStatusConfig = callStatusConfig;
    },
    setLight(state, light) {
      // light Bool
      state.light = light;
    },

    setCallModel(state, model) {
      state.callModel = model;
      window.call.callModel = model;
    },
    hideCallModal(state) {
      state.callModalOpened = false;
    },

    showCallModal(state) {
      state.callModalOpened = true;
    },
  },

  actions: {
    async cancelCall() {
      return true;
    },

    async callPrepare({ getters, commit }, residentsIdx) {
      const { select_token, activeResidentIdx } = getters;
      const res_idx = residentsIdx || activeResidentIdx;

      if (!res_idx) {
        return;
      }

      commit("setActiveResidentIdx", res_idx);
      const callModel = await api.startCall(res_idx, select_token);

      if (callModel) {
        commit("setCallModel", callModel);
      }
    },
  },
};
