<template>
  <AppModal
    :opened="opened"
    @close="close"
    class="call-modal"
    :class="{ [`status-${callStatus}`]: true, light }"
  >
    <div class="call-modal-media">
      <video src="your-src" />
    </div>

    <div class="call-modal-content">
      <div class="call-modal-head">
        <div class="col-modal-head--row">
          <div class="call-modal-head--col">
            <span class="logo">
              <img src="@/assets/img/white-logo.svg" alt="InterQR" />
            </span>
          </div>

          <div class="call-modal-head--col">
            <h2 class="call-family">{{ family }}</h2>
            <span class="call-apartment">{{ apartment }}</span>
          </div>
        </div>

        <div
          class="call-status"
          :style="statusStyles"
          :class="{ blinking: callStatusConfig.blinking }"
        >
          <div class="call-status-text">
            <SvgIcon :size="20" :name="iconName" v-if="iconName" />
            {{ callStatusConfig.msg }}
          </div>
        </div>
      </div>

      <div class="call-modal-body">
        <button class="button button-icon light-button" @click="lightToggle">
          <SvgIcon :size="32" :name="`${light ? 'light-active' : 'light'}`" />
        </button>

        <button
          class="button button-icon end-call-button call-action"
          v-if="callInProgress"
          @click="callCancel"
        >
          <img src="@/assets/img/end-call.svg" alt="End call" />
        </button>

        <div class="call-action" v-if="canCallAgain">
          <button class="button button-success button-fluid" @click="callAgain">
            <div class="button-wrapper">
              <div class="button--text">Сall again</div>
              <div class="button--icon"><SvgIcon name="call" /></div>
            </div>
          </button>

          <button
            class="button button-fluid diff-resident-button"
            @click="close"
          >
            <div class="button-wrapper">
              <div class="button--text">Select a different resident</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SvgIcon from "./icons/SvgIcon.vue";
import AppModal from "./AppModal.vue";
import { callStatuses } from "@/types/callStatuses";
const acceptedIcons = ["process", "ended", "call-started"];

export default {
  components: { AppModal, SvgIcon },
  emits: ["close"],
  props: {
    id: { type: [String, Number], required: true },
    opened: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters(["light", "callModel", "callStatusConfig", "callStatus"]),

    canCallAgain() {
      return this.callStatus === callStatuses[3];
    },

    statusStyles() {
      const { text_color, background_color } = this.callStatusConfig;
      const padding = background_color === "transparent" ? 0 : "10px 20px";

      return `color: ${text_color}; background: ${background_color}; padding: ${padding}`;
    },

    updatedStatus() {
      return this.callStatusConfig.status
        .toLowerCase()
        .replaceAll(" ", "-")
        .replaceAll(".", "");
    },

    family() {
      if (!this.callModel) {
        return "";
      }

      return this.callModel.resident_name;
    },

    apartment() {
      if (!this.callModel) {
        return "";
      }

      return this.callModel.apartment_name;
    },

    iconName() {
      if (acceptedIcons.indexOf(this.updatedStatus) === -1) {
        return null;
      }

      return this.updatedStatus;
    },

    callInProgress() {
      return this.callStatus === callStatuses[0];
    },
  },

  watch: {
    opened(opened) {
      if (!opened) {
        this.$store.dispatch("cancelCall");
      }
    },
    callStatus(status) {
      if (status === callStatuses[1]) {
        this.close();
      }
    },
  },

  methods: {
    ...mapMutations(["setLight"]),

    close() {
      this.$emit("close");
      this.setLight(false);
    },

    callAgain() {
      this.$store.dispatch("callPrepare");
      this.$store.commit("setLoading", true);

      window.call.startProcess = () => {
        this.$store.commit("setLoading", false);
        this.$store.commit("setCallStatus", callStatuses[0]);
      };
    },

    callCancel() {
      this.close();
    },

    lightToggle() {
      this.setLight(!this.light);
    },
  },
};
</script>

<style lang="scss">
.call-modal {
  min-height: 600px;
  overflow: hidden;
  max-width: 400px;
  background: #fff;

  --padding-y: 20px;
  --padding-x: 20px;
  --padding: var(--padding-y) var(--padding-x);

  @media (min-width: 400px) {
    --padding-y: 30px;
    --padding-x: 30px;
  }

  @media (min-width: 600px) {
    border-radius: 20px;
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.call-modal {
  display: flex;

  .call-modal-media,
  .call-modal-head::before {
    transition: opacity 0.3s;
  }

  &.light {
    .call-modal-media {
      opacity: 0.25;
    }

    .call-modal-head {
      color: #000;
      &:before {
        opacity: 0 !important;
      }
    }
  }
}

.call-modal-media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(80%);
  z-index: 1;
  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.call-modal-content {
  position: relative;
  z-index: 2;
  flex: 1;
  color: #fff;
  padding: var(--padding);
}

.diff-resident-button {
  margin-top: 20px;
}

.col-modal-head--row {
  display: flex;
  justify-content: space-between;
}
.call-modal-head {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: calc(var(--padding-y) * -1);
    left: calc(var(--padding-x) * -1);
    width: calc(100% + var(--padding-x) * 2);
    height: 250px;
    background: linear-gradient(
      181.17deg,
      #000000 -53.07%,
      rgba(0, 0, 0, 0.857548) 42.61%,
      rgba(0, 0, 0, 0) 97%
    );

    @media (min-width: 600px) {
      height: 200px;
    }
  }

  &--col {
    max-width: 50%;
    position: relative;
  }
}

.call-family,
.call-apartment {
  word-break: break-all;
}

.call-family {
  font-size: 18px;
}
.call-apartment {
  font-size: 15px;
  margin-top: 0.2em;
}

.call-status {
  font-size: 18px;
  margin-top: 15px;
  border-radius: 50px;
  position: absolute;
  top: 40px;

  &-text {
    align-items: center;
    display: flex;
    word-break: break-all;
    .svg-icon {
      margin-right: 10px;
    }
  }

  &.blinking {
    .call-status-text {
      animation: blinking 1.3s linear 0s infinite;
    }
  }
}

.end-call-button {
  width: 66px !important;
  height: 66px !important;
  z-index: 99999999;
  cursor: pointer;
  bottom: 30px;
}

.light-button {
  position: absolute !important;
  left: 30px;
  bottom: 50px;
  z-index: 99999999;
  cursor: pointer;
  color: inherit;
  .svg-icon {
    position: relative;
  }

  &:before {
    content: "";
    transition: background 0.3s;
    width: 200%;
    height: 200%;
    background: rgba(#000, 0.15);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  @media (min-width: 768px) {
    left: 40px;
    &:before {
      width: 160%;
      height: 160%;
    }
  }
}

.call-modal.light {
  .light-button {
    color: #fff;

    &:before {
      background: rgba(#000, 0.3);
    }
  }
}

.call-action {
  position: absolute !important;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  cursor: pointer;
  width: 100%;
  padding: 0 var(--padding-x);

  .button-success {
    width: calc(100% - 90px);
    margin-left: auto;
    margin-right: 0;
    display: block;
  }
}

.status-again {
  .light-button {
    bottom: 135px;
  }
}
</style>
