<template>
  <div class="apartments-list">
    <transition-group
      :css="false"
      @beforeEnter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <ApartmentCard
        v-for="(
          { id, name, floor, apt, hasResidents, resident_idx }, idx
        ) of apartments"
        :residentIdx="resident_idx"
        :key="id"
        class="apartments-list--item"
        :id="id"
        :title="name"
        :apt="apt"
        :floor="floor"
        :data-idx="idx"
        :hasResidents="hasResidents"
        @ring="(_id) => $emit('ring', _id)"
      />
    </transition-group>
  </div>
</template>

<script>
import gsap from "gsap";
import ApartmentCard from "./ApartmentCard.vue";

const delay = 0.25;
const duration = 0.5;
const outState = { autoAlpha: 0, height: 0 };

function makeDelay(el) {
  return el.dataset.idx * delay;
}

export default {
  components: { ApartmentCard },
  name: "ApartmentsList",
  emits: ["ring"],
  props: {
    apartments: { type: Array, required: true },
  },

  methods: {
    beforeEnter(el) {
      gsap.set(el, outState);
    },

    enter(el, done) {
      const delay = makeDelay(el);

      gsap.to(el, {
        y: 0,
        height: "auto",
        autoAlpha: 1,
        delay,
        duration,
        onComplete: done,
      });
    },

    leave(el, done) {
      const delay = makeDelay(el);
      gsap.to(el, { ...outState, delay, duration, onComplete: done });
    },
  },
};
</script>
