import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/styles/main.scss";
import store from "./store";
import { callStatuses } from "@/types/callStatuses";
import { addPolyfills } from "@/polifills";

const callSound = new Audio("/beep.mp3");

callSound.addEventListener("ended", () => {
  callSound.currentTime = 0;

  setTimeout(() => {
    callSound.play();
  }, 1000);
});

function lightModeToggle(status) {
  store.commit("setLight", status);
}

window.call = {
  cleanState() {
    store.commit("cleanCallState");
    store.dispatch("cleanState");
  },

  preloader: {
    show() {
      store.commit("setLoading", true);
    },

    hide() {
      store.commit("setLoading", false);
    },
  },

  lightMode: {
    on() {
      lightModeToggle(true);
    },

    off() {
      lightModeToggle(false);
    },
  },

  sound: {
    play() {
      if (callSound) {
        callSound.play();
      }
    },
    stop() {
      if (callSound) {
        callSound.pause();
        callSound.currentTime = 0;
      }
    },
  },

  getQr() {
    return store.getters.qrId;
  },

  getCallModel() {
    return store.getters.callModel;
  },

  setStatus(status) {
    store.commit("setCallStatusConfig", status);
  },

  startProcess() {
    store.commit("showCallModal");
  },

  stopProcess() {
    store.commit("setCallStatus", callStatuses[1]);

    setTimeout(() => {
      store.commit("setCallStatus", callStatuses[0]);
    }, 20);
  },

  toTryAgain() {
    store.commit("setCallStatus", callStatuses[3]);
  },
};

addPolyfills();

createApp(App).use(store).use(router).mount("#app");
