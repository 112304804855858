<template>
  <AppModal :opened="opened" @close="hide" class="residents-modal">
    <AppLayout
      title="Select Residents"
      name="residents"
      :useBack="true"
      @back="hide"
      v-if="apartmentName"
    >
      <div>
        <h2 class="subtitle">{{ apartmentName }}</h2>
        <ApartmentsList :apartments="residents" @ring="ringHandler" />
      </div>
    </AppLayout>
  </AppModal>
</template>

<script>
import ApartmentsList from "./ApartmentsList.vue";
import AppLayout from "./AppLayout.vue";
import AppModal from "./AppModal.vue";

export default {
  components: { AppModal, AppLayout, ApartmentsList },
  name: "ResidentsView",

  props: {
    id: { type: String, default: "" },
    opened: { type: Boolean, default: false },
  },

  computed: {
    apartmentName() {
      if (!this.id) {
        return null;
      }
      return this.$store.getters.apartmentsName(this.id);
    },

    residents() {
      if (!this.id) {
        return null;
      }

      return this.$store.getters.residents(this.id);
    },
  },

  methods: {
    hide() {
      this.$emit("hide");
    },

    ringHandler() {
      this.$store.commit("showCallModal");
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 600px) {
  .residents-modal {
    border-radius: 24px;
    min-height: 400px;
  }
  .residents {
    margin-top: 0;
    border: none;
  }
}
</style>
