<template>
  <form @submit.prevent class="app-search">
    <input
      type="text"
      class="search-field"
      :placeholder="placeholder"
      :value="modelValue"
      @input="updateValue"
    />
    <img
      src="@/assets/img/icons/search.svg"
      alt="Search"
      class="search-icon search-field-icon"
    />
    <span
      class="search-clear search-field-icon"
      v-if="modelValue.length"
      tabindex="0"
      @click="clear"
    >
      <img
        src="@/assets/img/icons/close.svg"
        width="12"
        height="12"
        alt="Close"
      />
    </span>
  </form>
</template>

<script>
export default {
  name: "AppSearch",
  props: {
    modelValue: { type: String, default: "" },
    delayMs: { type: Number, default: 300 },
    placeholder: { type: String, default: "Search" },
  },

  data() {
    return {
      searchTimeout: null,
    };
  },

  emits: ["update:modelValue"],

  methods: {
    clear() {
      this.updateValue({}, "", 0);
    },

    updateValue({ target }, val, timeout = this.delayMs) {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        let value = val ?? target.value;
        this.$emit("update:modelValue", value);
      }, timeout);
    },
  },
};
</script>

<style lang="scss">
.app-search {
  position: relative;
  display: block;
}

.search-field {
  width: 100%;
  height: 45px;
  border: 1px solid #323755;
  border-radius: 12px;
  padding-left: 50px;
  padding-right: 50px;
  // padding-right: 20px;
  background-color: #f3f3f3;
  font-size: 14px;
  width: 100%;
  &:focus {
    outline: none;
  }

  & + .search-icon {
    width: 16px;
    height: auto;
    left: 20px;
  }
}

.search-field-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.search-clear {
  right: 20px;
  width: 18px;
  height: 18px;
  appearance: button;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
  }
}
</style>
