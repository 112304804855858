<template>
  <div class="home">
    <AppSeacrh
      v-model="searchQueryLocal"
      placeholder="Type apartment or resident name"
      :delay-ms="300"
    />

    <div class="w-100 search-result">
      <ApartmentsList :apartments="filteredApartments" @ring="ringHandler" />

      <div class="search-empty-wrapper" v-if="!filteredApartments.length">
        <SearchEmpty />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApartmentsList from "../components/ApartmentsList.vue";
import AppSeacrh from "../components/AppSearch.vue";
import SearchEmpty from "../components/SearchEmpty.vue";
import { urlParser } from "@/utilles/urlParser";
export default {
  components: { AppSeacrh, ApartmentsList, SearchEmpty },
  name: "Apartments",
  data() {
    return {
      searchQueryLocal: "",
    };
  },

  computed: {
    ...mapGetters(["apartments", "searchQuery"]),

    qrId() {
      if (!this.$route.params.qrId) {
        return "";
      }

      return urlParser(this.$route.params.qrId);
    },

    filteredApartments() {
      const query = this.searchQuery.trim().toLowerCase();

      if (!query.length) {
        return this.apartments;
      }

      return this.apartments.filter(({ name, residents }) => {
        return (
          name.toLowerCase().includes(query) ||
          residents.some(({ name }) => name.toLowerCase().includes(query))
        );
      });
    },
  },

  watch: {
    searchQueryLocal(query) {
      if (query !== this.searchQuery) {
        this.$store.commit("setSearchQuery", query);
      }
    },

    searchQuery(query) {
      if (query !== this.searchQueryLocal) {
        this.searchQueryLocal = query;
      }
    },

    qrId(id) {
      this.upadateQrId(id);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.upadateQrId();
      this.$store.dispatch("fetchResidents");
    });
  },

  methods: {
    upadateQrId(id = this.qrId) {
      let qrId = typeof id === "string" ? id : "";
      this.$store.commit("setQrId", qrId);
    },

    ringHandler() {
      this.$store.commit("showCallModal");
    },
  },
};
</script>

<style lang="scss">
.apartments-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0 0;
}
.search-result {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  position: relative;
  flex: 1;

  .search-empty-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  }
  & > * {
    width: 100%;
  }
}
.home {
  display: flex;
  flex-direction: column;
}
</style>
